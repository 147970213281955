import $ from 'jquery';
import gaBuyEvent from './gaBuyEvent';

window.$ = window.jQuery = $;
export default function AddToCardEventInit() {
    jQuery(function () {
        $('form').each(function () {
            if ($(this).find('input[id^="ProductKey_"]').length) {
                $(this).on('submit', function (e) {
                    e.preventDefault();

                    let productBlock = $(this).closest('.product-list-item');
                    let productBlockDescription = $(this).closest('.product-description');

                    const productKey = $(this).find('[id^="ProductKey_"]').val();
                    const quantity = $(this).find('[id^="Quantity_"]').val() ?? 1;
                    const successRedirectUrl = $(this).find('[id^="SuccessRedirectUrl_"]').val();
                    const token = $(this).find('input[name="__RequestVerificationToken"]').val();

                    $.ajax({
                        type: 'POST',
                        url: '/umbraco/Merchello/StoreBasket/AddBasketItem',
                        dataType: 'json',
                        data: {
                            productKey,
                            quantity,
                            successRedirectUrl,
                            __RequestVerificationToken: token
                        },
                        success: function (result) {

                            gaBuyEvent(e, productKey, quantity);
    
                            if (productBlock.length) {
                                productBlock.addClass('added-item');
                            }

                            if (productBlockDescription.length) {
                                productBlockDescription.addClass('added-item');
                            }

                            $('.price-add-remove').hide();

                            let label = $('[data-muilabel="basketcount"]');
                            let basketCard = $('.cart');
                            if (label.length > 0) {
                                if (result.ItemCount > 0) {
                                    basketCard.removeClass('empty');
                                    basketCard.find('.num').text(result.ItemCount);
                                }
                            }
                        }
                    });
                });
            }
        });
    })
}