export default function preambleOpenClose() {
	let opener = $('.preamble__opener');
	let parent;
	let textBlock;
	let maxHeightInitial = 180;
	let textBlockHeight;
	let activeClass = 'active';

	if(opener.length == 0) return;

	opener.each(function() {
		parent = $(this.closest('.preamble'));
		textBlock = parent.find('.preamble__text');
		textBlockHeight = textBlock[0].scrollHeight;

		if(textBlockHeight <= maxHeightInitial) {
			parent.addClass('disabled');
		}

		$(this).on("click", function (e) {
			e.preventDefault();
			if(parent.hasClass(activeClass)) {
				parent.removeClass(activeClass);
				textBlock.css({"max-height": maxHeightInitial});
			} else {
				parent.addClass(activeClass);
				textBlock.css({"max-height": textBlockHeight});
			}
		});
	});
}