import searchFormPopup from "./components/searchFormPopup.js";
import initModal from "./components/initModal.js";
import initMobileNav from "./components/initMobileNav.js";
import headerSearchAutosuggest from "../headerSearchAutosuggest.js"
import searchInit from './components/searchInit';
import handleImage from './components/handleImage';
import setCurrency from './components/setCurrency';
import setLanguage from './components/setLanguage';

export default function header() {
    searchFormPopup();
    initModal();
    initMobileNav();
    headerSearchAutosuggest();
    searchInit();
    handleImage();
    setCurrency();
    setLanguage();
}