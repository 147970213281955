export default function initAnimations() {
    var widthScreen = $(window).width() <= '1024' ? '80%' : '30%';
    var timeForAnimation = 1000;
    var path = window.location.pathname; 
    if(path === '/basket/' || path === '/checkout/') {
        timeForAnimation = 100;
    }

    $('.product-page .product-col .add-to-basket-btn:not(.was-animated)').on('click', function(e) {
        $(this).addClass('was-animated');
        addAnimationToCart(widthScreen, $('.slideset'), $('.product'), timeForAnimation);
    })
    
    $('.product-list .add-to-basket-btn:not(.was-animated)').on('click', function(e) {
        $(this).addClass('was-animated');
        var imageHolder = $(this).parents('.product-list-item').find('.image-holder');
        if(!$(this).parents('.product-list-item.product-unavailable').length <= 0) {
            return;
        }
        widthScreen = '30%'
        addAnimationToCart( widthScreen, imageHolder, $('#main'), timeForAnimation);
    })

    function addAnimationToCart( widthScreen, image, place, timeForAnimation) {
        image.clone()
        .css({
            'position': 'absolute', 
            'z-index': 99,
            'width': widthScreen,
            'left': image.offset()['left'],
            'top': image.offset()['top']
        }).appendTo(place)
        .animate({
            top: $('.cart').offset()['top'],
            left: $('.cart').offset()['left'],
            opacity: 0,
            width: 20
        }, timeForAnimation, function() {
            $(this).remove();
        })
    }
}