export default function handleImage() {
	function iOSversion() {
		if (/iP(hone|od|ad)/.test(navigator.platform)) {
			var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
			return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
		}
	}

	var version = iOSversion();

	if (version && version[0] <= 14) {
		$('picture').each(function() {
			var sourceList = $(this).find('source');
			sourceList.each(function() {
				$(this).remove();
			});
		});
	}
}