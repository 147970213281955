import getCurrencyCode from './getCurrencyCode';

export default function gaBuyEvent(e, productKey, quantity) {
    let eventName = '';

    if ($(e.currentTarget).closest('.js-related-products').length) {
        eventName = 'add_to_related_card';
    } else if ($(e.currentTarget).closest('.js-viewed-products').length) {
        eventName = 'add_to_viewed_card';
    } else {
        eventName = 'add_to_card';
    }

    $.ajax({
        type: 'GET',
        url: `/umbraco/api/products/getproduct?key=${productKey}&quantity=${quantity}`,
        dataType: 'json',
        success: async function (product) {
            const currencyCode = await getCurrencyCode();

            dataLayer.push({
                'event': eventName,
                'ecommerce': {
                    'currencyCode': currencyCode,
                    'add': {
                        'product': product
                    }
                }
            });

            if (window.location.pathname.includes("checkout") || window.location.pathname.includes("basket")) {
                window.location.reload();
            }
        }
    });
}