import jqueryModalMin from '../../../libraries/jquery.modal.min';

export default function initModal() {
    jqueryModalMin();
    $('a[data-modal]').on('click',function () {
       
        $(this).modal({
            showClose: true
        });
        return false;
    });
}