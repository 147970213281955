import addToCardEventInit from "../modules/addToCardEvent";
import initAnimations from '../modules/initAnimation';

export default async function loadProducts() {

    await loadProducts('/umbraco/surface/store/GetNovelties', '.js-novelties-block');
    await loadProducts('/umbraco/surface/store/GetActions', '.js-actions-block');

    addToCardEventInit();
    initAnimations();

    function loadProducts(url, blockName) {
        let request = $.ajax({
            method: "GET",
            url
        }).done(function (htmlString) {
            if (!htmlString) return;

            var $html = $(htmlString);
            var $productItems = $html.children();
            var $carousel = $(blockName).find('.product-list').data('swiper');
            
            $productItems.each(function() {
                if (this.classList.contains('swiper-slide')) {               
                    $carousel.appendSlide(this);
                } else {
                    const slide = $(`<div class="swiper-slide"></div>`);
                    slide.append(this);
                    $carousel.appendSlide(slide.get(0));
                }
            });

            $carousel.update();
            initAnimations();
        });

        return request;
    }
}