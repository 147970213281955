import Swiper from 'swiper';
import {Navigation, Manipulation} from 'swiper/modules';

function getCarousel(list, showItemsCount) {
	const swiper = new Swiper(list, {
		slidesPerView: 3,
		slidesPerGroup: 3,
		speed: 1400,
		observer: true,
		modules: [Navigation, Manipulation],
		createElements: true,
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		breakpoints: {
			479: {
				slidesPerView: 3,
				slidesPerGroup: 3,
			},
			767: {
				slidesPerView: showItemsCount || 4,
				slidesPerGroup: showItemsCount || 4,
			},
			1025: {
				slidesPerView: showItemsCount || 5,
				slidesPerGroup: showItemsCount || 5,
			}
		}
	});

	$(list).data('swiper', swiper);
}

export default (productList) => {
	if (productList) {
		getCarousel(productList);
	} else {
		var list = $(".product-list.swiper");
		var itemCount = list.parents(".top-sales-products").length > 0 ? 3 : undefined;
		list.each(function () {
			getCarousel(this, itemCount);
		});
	}
}
