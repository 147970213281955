import Swiper from 'swiper';

export default function categoriesSlider() {
    new Swiper('.category-swiper', {
        slidesPerView: 3,
        speed: 200,
        createElements: true,
        breakpoints: {
            567: {
                slidesPerView: 'auto'
            }
        }
    });
}