export default async function getCurrencyCode() {
    try {
        const result = await $.ajax({
            type: 'GET',
            url: `/umbraco/surface/currency/getCurrentCurrency`,
            dataType: 'json'
        });

        return result;
    } catch (error) {
        console.error(error);
        return 'UAH';
    }
}