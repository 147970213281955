export default function searchFormPopup() {
    const btnOpener = $('.search-opener');
    const btnClose = $('.search-form-holder .reset');

    let toggleSearch = () => {
        $('#autoSuggestSearchMobile').html('');
        $('body').toggleClass('search-active');
    }

    btnClose.on('click', toggleSearch);
    btnOpener.on('click', toggleSearch);
}