import $ from 'jquery';

window.$ = window.jQuery = $;
export default function headerSearchAutosuggest () {
    var searcInput = $('#searchInput, #searchInputMobile');
    var searchInputValue = '';

    searcInput.on('input', function () {
        searchInputValue = this.value;
        if (searchInputValue && searchInputValue.length >= 3) {
            searcInput.each(function(){
                this.value = searchInputValue;
            });
            
            $.ajax({
                url: '/autosuggestSearch?s=' + encodeURIComponent(this.value) + '&p.s=score' + '&lang=' + getCurrentLanguage(),
                type: 'GET',
                success: function (data) {
                    $('#autoSuggestSearch').html('');
                    $('#autoSuggestSearch').append($(data));
                    $('#autoSuggestSearchMobile').html('');
                    $('#autoSuggestSearchMobile').append($(data));
                }
            });

            function getCurrentLanguage() {
                var siteLanguage = ''
            
                switch (window.location.pathname.split('/')[1]) {
                    case 'ru':
                        siteLanguage = 'ru-RU';
                        break;
                    case 'en':
                        siteLanguage = "en-US"
                        break;
                    case 'pl':
                        siteLanguage = "pl-PL"
                        break;
                    case 'es':
                        siteLanguage = "es-ES"
                        break;
            
                    default:
                        siteLanguage = "uk-UA";
                        break;
                }
            
                return siteLanguage;
            }
        }
        else {
            $('#autoSuggestSearch').html('');
            $('#autoSuggestSearchMobile').html('');
        }
    });
};
