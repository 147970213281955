export default function searchInit() {

    var searchForm = $('.search-form, .mobile-search-form');
    
    searchForm.each(function() {
        var form = $(this);
        var searchInput = form.find('input[type="search"]');
        var searchReset = form.find('.search-clear');
        
        searchInput.on('keydown', function (e) {
            var $this = $(this);
            var value = $this.val();
            if (value) {
                searchReset.show();
                if (value.length == 1 && e.key == 'Backspace') {
                    searchReset.hide();
                }
            } else {
                searchReset.hide();
            }
        });

        searchInput.on('focus', function() {
            form.addClass('active');
        });
    
        searchInput.on('blur', function() {
            form.removeClass('active');
        });

        searchReset.on('click', function (e) {
            e.preventDefault();
            var $this = $(this);
            searchInput.val('').text('');
            $this.hide();
        });
        var value = searchInput.val();
        if (value) {
            searchReset.show();
        } else {
            searchReset.hide();
        }
    })
  

    var isValidSearchForm = function (form) {
        var searchWord = form.find('input[type="search"]');
        return !!searchWord && !!searchWord.val();
    }

    $('.search-form').on('submit', function (e) { return isValidSearchForm($(this)); });
    $('.mobile-search-form').on('submit', function (e) { return isValidSearchForm($(this)); });
}