export default function() {
 /*
 * Simple Mobile Navigation
 */
; (function ($) {
    function MobileNav(options) {
        this.options = $.extend({
            container: null,
            hideOnClickOutside: false,
            menuActiveClass: 'nav-active',
            clearClass: '',
            menuOpener: '.nav-opener',
            menuDrop: '.nav-drop',
            toggleEvent: 'click',
            outsideClickEvent: 'click touchstart pointerdown MSPointerDown'
        }, options);
        this.initStructure();
        this.attachEvents();
    }
    MobileNav.prototype = {
        initStructure: function () {
            this.page = $('html');
            this.container = $(this.options.container);
            this.opener = this.container.find(this.options.menuOpener);
            this.drop = this.container.find(this.options.menuDrop);
        },
        attachEvents: function () {
            var self = this;

            if (activateResizeHandler) {
                activateResizeHandler();
                activateResizeHandler = null;
            }

            this.outsideClickHandler = function (e) {
                if (self.isOpened()) {
                    var target = $(e.target);
                    if (!target.closest(self.opener).length && !target.closest(self.drop).length) {
                        self.hide();
                    }
                }
            };

            this.openerClickHandler = function (e) {
                e.preventDefault();
                self.toggle();
            };

            this.opener.on(this.options.toggleEvent, this.openerClickHandler);
        },
        isOpened: function () {
            return this.container.hasClass(this.options.menuActiveClass);
        },
        show: function () {
            this.container.addClass(this.options.menuActiveClass);
            if(this.container.hasClass(this.options.clearClass)) {
                this.container.removeClass(this.options.clearClass)
            } 
            if (this.options.hideOnClickOutside) {
                this.page.on(this.options.outsideClickEvent, this.outsideClickHandler);
            }
        },
        hide: function () {
            this.container.removeClass(this.options.menuActiveClass);
            if (this.options.hideOnClickOutside) {
                this.page.off(this.options.outsideClickEvent, this.outsideClickHandler);
            }
        },
        toggle: function () {
            if (this.isOpened()) {
                this.hide();
            } else {
                this.show();
            }
        },
        destroy: function () {
            this.container.removeClass(this.options.menuActiveClass);
            this.opener.off(this.options.toggleEvent, this.clickHandler);
            this.page.off(this.options.outsideClickEvent, this.outsideClickHandler);
        }
    };

    var activateResizeHandler = function () {
        var win = $(window),
          doc = $('html'),
          resizeClass = 'resize-active',
          flag, timer;
        var removeClassHandler = function () {
            flag = false;
            doc.removeClass(resizeClass);
        };
        var resizeHandler = function () {
            if (!flag) {
                flag = true;
                doc.addClass(resizeClass);
            }
            clearTimeout(timer);
            timer = setTimeout(removeClassHandler, 500);
        };
        win.on('resize orientationchange', resizeHandler);
    };

    $.fn.mobileNav = function (options) {
        return this.each(function () {
            var params = $.extend({}, options, { container: this }),
              instance = new MobileNav(params);
            $.data(this, 'MobileNav', instance);
        });
    };

    var body = $('body');
    var currentWidth = body.width();
    $(window).on("resize", function() {
        currentWidth = body.width();
    });

    var activeClassName = "show-second-level";
    var navList = $(".main-nav__list .has-drop");
    var navListWrapper = $(".main-nav");
    var navBackBtn = $(".main-nav__second-level-back");
    var toggleSlideState = function(row) {
        row.find(".main-nav__additional-list").slideToggle(150);
        row.toggleClass("active");
    };
    navBackBtn.on("click", function() {
        if(currentWidth <= 1279) {
            navListWrapper.removeClass(activeClassName);
            var targetItem = $(".main-nav__second-level").find(".main-nav__second-level-item.active");
            targetItem.removeClass("active");

            var activeRow = $(".main-nav__second-level-row.active");
            toggleSlideState(activeRow);
        }
    });
    navList.each(function() {
        $(this).find("a").on('click', function(e) {
            if(currentWidth <= 1279) {
                e.preventDefault();
                navListWrapper.addClass(activeClassName);
                var targetItem = $(".main-nav__second-level").find('.main-nav__second-level-item[data-name="' + $(this).find("span").html() + '"]');
                targetItem.addClass("active");
            }
        });
    });
    $(".main-nav__second-level-row.has-list .main-nav__second-level-link").each(function() {

        var row = $(this).parent(".main-nav__second-level-row");
        var isOpened = false;
        $(this).find("a").on('click', function(e) {
            isOpened = $(this).parents(".main-nav__second-level-row.has-list").hasClass("active");
            if (!isOpened) {
                e.preventDefault();
            } else {
                e.stopPropagation()
            }
        });

        $(this).on("click", function() {
            if(currentWidth <= 1279) {
                var activeRow = $(".main-nav__second-level-row.active");
                toggleSlideState(activeRow);
                if (activeRow.find(".main-nav__second-level-link a").html() !== row.find(".main-nav__second-level-link a").html()) {
                    toggleSlideState(row);
                }
            }
        });
    });
    $('.close-nav').on('click', function() {
        $('.nav-active').removeClass("nav-active")
    });
}(jQuery));
}
