import jcf from "../../../libraries/jcf/jcf";
import jcfSelect from "../../../libraries/jcf/jcf.select";

export default function setCurrency() {
    jcfSelect();
    jcf.setOptions('Select', {
        wrapNative: false,
        fakeDropInBody: false,
        maxVisibleItems: 4
    });
    jcf.replaceAll();

    $('.js-currency').on('change', function () {
        const newCurrency = $(this).val();
        document.cookie = `SiteCurrency=${newCurrency}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;

        window.location.reload();
    });
}