import header from '../modules/header/index';
import categoriesSlider from '../modules/categoriesSlider';
import initAnimations from '../modules/initAnimation';
import loadProducts from '../modules/loadHomeProducts';
import productCarousel from "../modules/productCarousel";
import instagramSlider from '../modules/instagramSlider';
import preambleOpenClose from '../modules/preambleOpenClose';

window.$ = window.jQuery = $;

jQuery(function () {
    header();
    categoriesSlider();
    initAnimations();
    loadProducts();
    preambleOpenClose();
    productCarousel();    
    instagramSlider();
});
