import jcf from "../../../libraries/jcf/jcf";
import jcfSelect from "../../../libraries/jcf/jcf.select";

export default function setLanguage() {
    jcfSelect();
    jcf.setOptions('Select', {
        wrapNative: false,
        fakeDropInBody: false,
        maxVisibleItems: 4
    });
    jcf.replaceAll();

    $('.js-language').on('change', function () {
        window.location = $(this).val();
    });
}